import React, { useState } from 'react';
import { Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const NavMenu = () => {

    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isSolutionsOpen, setSolutionsOpen] = useState(false);
    const [isResourcesOpen, setResourcesOpen] = useState(false);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const closeMobileMenu = () => {
        setIsMobileMenuOpen(false);
        setSolutionsOpen(false);
        setResourcesOpen(false);
    };

    const toggleSubMenu = (menu) => {
        if (menu === 'solutions') {
            setSolutionsOpen(!isSolutionsOpen);
        } else if (menu === 'resources') {
            setResourcesOpen(!isResourcesOpen);
        }
    };

    const handleMouseEnter = (menu) => {
        if (!isMobileMenuOpen) { // Only for desktop views
            if (menu === 'solutions') {
                setSolutionsOpen(true);
            } else if (menu === 'resources') {
                setResourcesOpen(true);
            }
        }
    };

    const handleMouseLeave = (menu) => {
        if (!isMobileMenuOpen) { // Only for desktop views
            if (menu === 'solutions') {
                setSolutionsOpen(false);
            } else if (menu === 'resources') {
                setResourcesOpen(false);
            }
        }
    };
    return (
        <>
            <button onClick={toggleMobileMenu} className="navbar-toggler">
                <span className='navbar-toggler-icon'></span>
            </button>

            <Navbar.Collapse id="basic-navbar-nav" className={`justify-content-end header-link nav-links ${isMobileMenuOpen ? 'open' : ''}`}>
                <div className="nav-link">
                    <NavLink onClick={closeMobileMenu} to="/">Home</NavLink>
                    
                    <div 
                        className={`dropdown ${isSolutionsOpen ? 'open' : ''}`}
                        onMouseEnter={() => handleMouseEnter('solutions')}
                        onMouseLeave={() => handleMouseLeave('solutions')}
                    >
                        <NavLink className={'arrowicon'} 
                            onClick={() => isMobileMenuOpen ? toggleSubMenu('solutions') : closeMobileMenu()}
                            to="#"
                        >
                            Solutions
                        </NavLink>
                        {isSolutionsOpen && (
                            <div className="submenu">
                                <NavLink onClick={closeMobileMenu} to="/features">Radiology Patient Scheduling</NavLink>
                                <NavLink onClick={closeMobileMenu} to="/features">Radiology Patient Engagement</NavLink>
                                <NavLink onClick={closeMobileMenu} to="/features">Radiology Referral Management</NavLink>
                            </div>
                        )}
                    </div>
                    
                    <div 
                        className={`dropdown ${isResourcesOpen ? 'open' : ''}`}
                        onMouseEnter={() => handleMouseEnter('resources')}
                        onMouseLeave={() => handleMouseLeave('resources')}
                    >
                        <NavLink className={'arrowicon'}
                            onClick={() => isMobileMenuOpen ? toggleSubMenu('resources') : closeMobileMenu()}
                            to="#"
                        >
                            Resources
                        </NavLink>
                        {isResourcesOpen && (
                            <div className="submenu">
                                <NavLink onClick={closeMobileMenu} to="/integration">Integration</NavLink>
                                <NavLink onClick={closeMobileMenu} to="/blog">Blog</NavLink>
                                <NavLink onClick={closeMobileMenu} to="/roi">ROI Calculator</NavLink>
                            </div>
                        )}
                    </div>

                    <NavLink onClick={closeMobileMenu} to="/customers">Customers</NavLink>                
                    <NavLink onClick={closeMobileMenu} to="/company">About us</NavLink>
                    <NavLink onClick={closeMobileMenu} className="morelink d-block d-lg-none" to="/contact-us">Contact us</NavLink>
                </div>
            </Navbar.Collapse>
        </>
    );
}

export default NavMenu