import React, { useState } from "react";
import { Container } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import ReactPlayer from "react-player"

const HomeBanner = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <Container fluid className='p-0 '>
            <div className="section hero">
                <div className="hero-container home-hero-container">
                    <h1>Innovative online appointment scheduling and patient engagement software<br /><div className='subtitle'>openDoctor's comprehensive set of radiology scheduling and workflow automation tools can address even the most intricate healthcare system needs.</div></h1>
                    <a className="button circle" onClick={handleShow}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21 12l-18 12v-24z" /></svg></a>
                    <span>watch our video</span>
                    <Modal
                        show={show}
                        onHide={handleClose}
                        keyboard={false}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        backdrop="static"
                    >
                        <Modal.Header closeButton>

                        </Modal.Header>
                        <Modal.Body className="p-0" closeButton>
                            <div className="player-wrapper">
                                <ReactPlayer
                                    className="react-player"
                                    url="https://player.vimeo.com/video/236970476"
                                    playing={true}
                                    controls
                                    width="100%"
                                    height="100%"

                                />
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
                <div className="home-animation">
                    <div className="object buildings"></div>
                    <div className="object patient"></div>
                    <div className="object patient2"></div>
                    <div className="object background"></div>
                    <div className="object sun"></div>
                    <div className="object alarm"></div>
                    <div className="object moon setting finishsetting"></div>
                </div>
            </div>
        </Container>
    )
}
export default HomeBanner