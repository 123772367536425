import React from 'react';
import { NavLink } from 'react-router-dom';
import PageBanner from '../components/PageBanner';
import BlogDetailPage from '../components/BlogDetailPage';
import BlogBackButton from '../components/BlogBackButton';

const BlogContent3 = () => {
    const newsContent = {
        title: 'How to Leverage Automated Solutions to Enhance Patient Engagement and Satisfaction',
        paragraphs: [
            <>
                Radiology practices face complex challenges today. According to the <NavLink rel="noreferrer noopener" to="https://www.acr.org/Practice-Management-Quality-Informatics/ACR-Bulletin/Articles/July-2024/Radiology-Workforce-Shortage-and-Growing-Demand-Something-Has-to-Give" target="_blank">American College of Radiology</NavLink>, there has been an increase in the use of medical imaging, while a shortage of radiology personnel is stretching healthcare systems and workers thin. On top of that, data from <NavLink rel="noreferrer noopener" to="https://www.academicradiology.org/article/S1076-6332(24)00228-9/pdf" target="_blank">Academic Radiology</NavLink> indicates that patients miss nearly a quarter of all outpatient imaging appointments. Fortunately, patient engagement tools like openDoctor's <NavLink to="https://opendr.com/features"><u>radiology patient scheduling software</u></NavLink> can help automate critical points in the patient journey, including appointment scheduling, communication, intake and registration, and administrative office tasks.
            </>,
            <>
                Let's explore the 4 key stages of the patient journey to identify areas where automated solutions can enhance patient engagement and satisfaction:
            </>,
            <>
                <strong><u>1.&nbsp; Acquiring New Patients</u></strong>
            </>,
            <>
                Radiology patient scheduling software allows healthcare systems to:
            </>,
            <>
                <ul>
                    <li>Implement a real-time appointment booking process</li>
                    <li>Send patients direct scheduling links for their ordered exam</li>
                    <li>Include an option in their phone booking workflow for patients to receive a text with online scheduling links, to ensure patients aren't lost when call volumes are high</li>
                    <li>Facilitate appointment booking outside of normal business hours, which many patients prefer</li>
                    <li>Automatically follow up on canceled appointments, to encourage patients to schedule again</li>
                    <li>Increase referrals from other providers by facilitating a seamless system for referral bookings</li>
                </ul>
            </>,
            <>
                <strong><u>2.&nbsp; Pre-Appointment Communication</u></strong>
            </>,
            <>
                Radiology patient scheduling software also automates and streamlines pre-appointment patient communication, allowing healthcare systems to:
            </>,
            <>
                 <ul>
                    <li>Reduce no-shows through appointment reminders</li>
                    <li>Send exam preparation information, ensuring that patients show up to their appointments prepared</li>
                    <li>Encourage appointment rebooking to reduce unrecovered cancelations</li>
                </ul>
            </>,
            <>
                <strong><u>3.&nbsp; In-Office Experience</u></strong>
            </>,
            <>
                Before a patient's appointment, radiology software solutions facilitate registration and payment processes to help reduce in-office wait times and increase operational efficiency. These tools allow healthcare systems to:
            </>,
            <>
                <ul>
                    <li>Send automated requests to patients to complete registration forms online</li>
                    <li>Reduce paper document management and time spent scanning forms</li>
                    <li>Reduce time at the front desk by enabling patients to pay online before their visit</li>
                </ul>
            </>,
            <>
                <strong><u>4.&nbsp; Post-Appointment Engagement</u></strong>               
            </>,
            <>
                The value of radiology scheduling software does not end with the patient's appointment. These tools allow healthcare systems to continue to build relationships with their patients by:
            </>,
            <>
                <ul>
                    <li>Sending follow-up exam scheduling links</li>
                    <li>Deploying annual and/or birthday appointment reminders</li>
                    <li>Allowing patients to view their imaging results online</li>
                    <li>Requesting patient feedback through survey responses</li>
                </ul>              
            </>,
            <>
                <strong><u>Learn More</u></strong>                   
            </>,
            <>
                As an early innovator in radiology patient experience, openDoctor is dedicated to bringing passion and expertise to an industry undergoing rapid change. For years, openDoctor has delivered innovative online appointment scheduling and patient engagement solutions to healthcare systems and practices of all sizes and levels of complexity. Our comprehensive set of tools is highly adaptable and capable of addressing even the most intricate scheduling and workflow challenges.                  
            </>,
            <>
                Let our experience guide you. For more information on how radiology patient scheduling software can enhance your healthcare system's patient experience, please <NavLink to="https://opendr.com/contact-us"><u>get in touch with us</u></NavLink>.
            </>,          

            <>
                <div className='col-md-12 text-center'>
                    <BlogBackButton />
                </div>
            </>
        ],
    };

    return (
        <div>
            <PageBanner title="Blog" />
            <BlogDetailPage title={newsContent.title} paragraphs={newsContent.paragraphs} />
        </div>
    )
}
export default BlogContent3