import { Container, Row, Col } from 'react-bootstrap';
import { NavLink } from "react-router-dom";

function BlogContainer() {
   
    return (
        <div>
            <div className="pagebanner p-5 p-lg-5 p-sm-4 ">
                <Container className="my-2">
                    <Row className="justify-content-md-center text-center">
                        <Col md={10}>
                            <h2 className="fw-semibold mt-4 mb-3">
                                Blogs
                            </h2>                           
                        </Col>
                    </Row>
                </Container>
            </div>


            <Container fluid className='p-5 newsupdate'>
            <div>
                    <div className="justify-content-md-center row">
                        <div className="col-lg-5">
                            <h4><NavLink to="/blog/best-practices-for-managing-complex-radiology-scheduling-challenges">Best Practices for Managing Complex Radiology Scheduling Challenges</NavLink></h4>
                            <p>Consumers expect frictionless online scheduling, communication, and payment processes from every business they engage with, regardless of the industry. We have been booking airline tickets and hotel reservations online for nearly three decades, but some industries, such as healthcare, have experienced a slower pace of innovation.</p>
                            <NavLink className="morelink" to="/blog/best-practices-for-managing-complex-radiology-scheduling-challenges">Read More</NavLink>
                        </div>

                        <div className="col-lg-5">                            
                            <h4><NavLink to="/blog/selecting-the-best-radiology-patient-scheduling-software-for-your-healthcare-system">Selecting the Best Radiology Patient Scheduling Software for your Healthcare System</NavLink></h4>
                            <p>According to data from <NavLink rel="noreferrer noopener" to="https://www.academicradiology.org/article/S1076-6332(24)00228-9/pdf" target="_blank">Academic Radiology</NavLink>, patients miss nearly a quarter of all outpatient imaging appointments. With the increasing use of medical imaging and a shortage of radiology personnel, it's the perfect time for healthcare systems to consider incorporating radiology patient scheduling software into their technology infrastructure.</p>
                            <NavLink className="morelink" to="/blog/selecting-the-best-radiology-patient-scheduling-software-for-your-healthcare-system">Read More</NavLink>
                        </div>

                        <div className="col-lg-5">
                            <div className="border-top my-4"></div>
                            <h4><NavLink to="/blog/how-to-leverage-automated-solutions-to-enhance-patient-engagement-and-satisfaction">How to Leverage Automated Solutions to Enhance Patient Engagement and Satisfaction</NavLink></h4>
                            <p>Radiology practices face complex challenges today. According to the <NavLink rel="noreferrer noopener" to="https://www.acr.org/Practice-Management-Quality-Informatics/ACR-Bulletin/Articles/July-2024/Radiology-Workforce-Shortage-and-Growing-Demand-Something-Has-to-Give" target="_blank">American College of Radiology</NavLink>, there has been an increase in the use of medical imaging, while a shortage of radiology personnel is stretching healthcare systems and workers thin. On top of that, data from <NavLink rel="noreferrer noopener" to="https://www.academicradiology.org/article/S1076-6332(24)00228-9/pdf" target="_blank">Academic Radiology</NavLink> indicates that patients miss nearly a quarter of all outpatient imaging appointments.</p>
                            <NavLink className="morelink" to="/blog/how-to-leverage-automated-solutions-to-enhance-patient-engagement-and-satisfaction">Read More</NavLink>
                        </div>

                        <div className="col-lg-5">
                            <div className="border-top my-4"></div>
                            <h4><NavLink to="/blog/innovation-and-customer-impact-how-online-airfare-booking-can-help-inform-radiology-appointment-scheduling">Innovation and Customer Impact: How Online Airfare Booking Can Help Inform Radiology Appointment Scheduling</NavLink></h4>
                            <p>Consumers expect smooth online scheduling, communication, and payment processes from every business they interact with, regardless of the industry. We have been booking airline tickets online for three decades, and there are valuable insights to be gained from consumers' embrace of digital airline innovations that can be applied to industries that have been slower to adapt, such as online healthcare appointment scheduling.</p>
                            <NavLink className="morelink" to="/blog/innovation-and-customer-impact-how-online-airfare-booking-can-help-inform-radiology-appointment-scheduling">Read More</NavLink>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
}
export default BlogContainer;