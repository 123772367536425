import React from "react";
//import Dropdown from 'react-bootstrap/Dropdown';
//import DropdownButton from 'react-bootstrap/DropdownButton';
import { Container } from "react-bootstrap";
//import { NavLink } from "react-router-dom";

const PageBottom = props => {
    return (
        <div className="light-gray border-top p-5 text-center">
            <Container>                
                <div>
                    <i>"As an early innovator in radiology patient experience, openDoctor is dedicated to bringing passion and expertise to an industry undergoing rapid change. For years, openDoctor has delivered innovative online appointment scheduling and patient engagement solutions to healthcare systems and practices of all sizes and levels of complexity. Our comprehensive set of tools is highly adaptable and capable of addressing even the most intricate scheduling and workflow challenges."</i>
                    {/*<Button variant="primary" size="lg" className="text-white text-uppercase appt-btn"><NavLink to="/inquiry"> Click here for Free Consultation</NavLink></Button>
                        <DropdownButton id="dropdown-item-button" title="Please select an expert">
                            <Dropdown.Item as="button">CEO</Dropdown.Item>
                            <Dropdown.Item as="button">Head of Product</Dropdown.Item>
                            <Dropdown.Item as="button">Head of Business Development</Dropdown.Item>
                        </DropdownButton>*/}
                </div>
            </Container>
        </div>
    );
}

export default PageBottom;