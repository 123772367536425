import React from 'react';
import { Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const BlogBackButton = props => {
    return (
        <NavLink to="/blog"><Button className="my-4 mx-0 mx-md-3" variant="outline-primary" id="button-addon1">Back to Blogs</Button></NavLink>
    )
}

export default BlogBackButton