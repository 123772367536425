import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import logo1 from '../images/logos/university-radiology.png';
import logo2 from '../images/logos/sjra.png';
import logo3 from '../images/logos/ami.png';

import logo4 from '../images/logos/SimonMed.png';
import logo5 from '../images/logos/ProScanLogo.png';
import logo6 from '../images/logos/bay_care.png';

import logo7 from '../images/logos/oia.png';
import logo8 from '../images/logos/fairfax.jpg';
import logo9 from '../images/logos/SDMI.jpg';


const CustomersLogo = () => {
    return (
        <div>
            <Container fluid className="customerlogo mb-5">
                <Row className="justify-content-md-center text-center">
                    <Col lg={2} className="p-3"><img src={logo1} width="160px" alt="University Radiology" /></Col>
                    <Col lg={2} className="p-3"><img src={logo2} width="160px" alt="South Jersey Radiology" /></Col>
                    <Col lg={2} className="p-3"><img src={logo3} width="150px" alt="Atlantic Medical Imaging" /></Col>
                </Row>

                <Row className="justify-content-md-center text-center">
                    <Col lg={2} className="p-3"><img src={logo4} width="160px" alt="Simonmed" /></Col>
                    <Col lg={2} className="p-3"><img src={logo5} width="100px" alt="Proscan Imaging" /></Col>
                    <Col lg={2} className="p-3"><img src={logo6} width="150px" alt="BayCare" /></Col>
                </Row>
                <Row className='justify-content-md-center text-center pt-md-4'>
                    <Col lg={2} className="p-3"><img src={logo7} width="150px" alt="Outpatient Imaging Affiliates" /></Col>
                    <Col lg={2} className="p-3"><img src={logo8} width="100px" alt="Fairfax Radiology Centers" /></Col>
                    <Col lg={2} className="p-3"><img src={logo9} width="80px" alt="SDMI" /></Col>
                </Row>
            </Container>
        </div>
    )
}

export default CustomersLogo