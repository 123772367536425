import React from 'react';
import BlogContainer from '../components/BlogContainer';

const Blog = () => {
    return (
        <div>
            <BlogContainer />
        </div>
    )
}
export default Blog