import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import { Container, Button } from "react-bootstrap";
import flag from '../images/flag.webp';

const flagImages = [
    flag, flag, flag, flag, flag, flag, flag,
];

const MultipleLocation = () => {

    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % flagImages.length);
        }, 1000); // Change the interval as needed (in milliseconds)

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className="section section-home-alternate multiplelocation">   
                <div className="object multiple-locations">
                    <div className="flag-container">
                        {flagImages.map((image, index) => (
                            <img
                                key={index}
                                src={image}
                                alt={`Flag ${index + 1}`}
                                className={`flag ${currentIndex >= index ? 'visible' : ''}`}
                            />
                        ))}
                    </div>
                </div>
                <Container>
                    <div className="section-content home-section-content">
                        <h2>Your healthcare system requires a robust solution. <br/>openDoctor can help.</h2>
                        <p>Radiology scheduling software can relieve healthcare systems of the burden of managing complex appointment workflows. It also enables larger systems to consolidate scheduling across multiple departments and facilities, leading to more efficient resource utilization. openDoctor is built to seamlessly navigate even the most complex scheduling logic.</p>
                        <NavLink to="/customers"><Button className="button large primary">Case Studies</Button></NavLink>
                    </div>
                </Container>           
        </div>
    );
}

export default MultipleLocation;