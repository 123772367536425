import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Helmet } from 'react-helmet';
import Home from './pages/Home';
import Features from './Features';
import Integration from './pages/Integration';
import Blog from './pages/Blog';
import BlogContent1 from './pages/BlogContent1';
import BlogContent2 from './pages/BlogContent2';
import BlogContent3 from './pages/BlogContent3';
import BlogContent4 from './pages/BlogContent4';
import Customers from './pages/Customers';
import Roi from './pages/Roi';
import Company from './pages/Company';
import Careers from './pages/Careers.js';
import NewsUpdates from './pages/NewsUpdates';
import NewsUpdatesContent1 from './pages/NewsUpdatesContent1';
import NewsUpdatesContent2 from './pages/NewsUpdatesContent2';
import NewsUpdatesContent3 from './pages/NewsUpdatesContent3';
import NewsUpdatesContent4 from './pages/NewsUpdatesContent4';
import NewsUpdatesContent5 from './pages/NewsUpdatesContent5';
import NewsUpdatesContent6 from './pages/NewsUpdatesContent6';
import NewsUpdatesContent7 from './pages/NewsUpdatesContent7';
import NewsUpdatesContent8 from './pages/NewsUpdatesContent8';
import NewsUpdatesContent9 from './pages/NewsUpdatesContent9';
import NewsUpdatesContent10 from './pages/NewsUpdatesContent10';
import NewsUpdatesBlog1 from './pages/NewsUpdatesBlog1';
import NewsUpdatesBlog2 from './pages/NewsUpdatesBlog2';
import NewsUpdatesCaseStudy1 from './pages/NewsUpdatesCaseStudy1';
import NewsUpdatesCaseStudy2 from './pages/NewsUpdatesCaseStudy2';
import NewsUpdatesNews1 from './pages/NewsUpdatesNews1';
import NewsUpdatesNews2 from './pages/NewsUpdatesNews2';
import NewsUpdatesNews3 from './pages/NewsUpdatesNews3';
import NewsUpdatesNews4 from './pages/NewsUpdatesNews4';
import NewsUpdatesUpdates1 from './pages/NewsUpdatesUpdates1';
import NewsUpdatesUpdates2 from './pages/NewsUpdatesUpdates2';
import NewsUpdatesUpdates3 from './pages/NewsUpdatesUpdates3';
import NewsUpdatesUpdates4 from './pages/NewsUpdatesUpdates4';
import ContactUs from './pages/ContactUs';
import Faq from './pages/Faq';
import TermsConditions from './pages/TermsConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Inquiry from './pages/Inquiry';
import ProjectManager from './pages/ProjectManager';
import QualityAssuranceTester from './pages/QualityAssuranceTester';
import SeniorPhpDeveloper from './pages/SeniorPhpDeveloper';
import BusinessAnalystIntern from './pages/BusinessAnalystIntern';
import InterfaceDeveloperIntern from './pages/InterfaceDeveloperIntern';
import FullStackSoftwareDeveloper from './pages/FullStackSoftwareDeveloper';
import AutomationTestEngineer from './pages/AutomationTestEngineer';
import EnterpriseSolution from './pages/EnterpriseSolution';
import RemindersNotifications from './pages/RemindersNotifications';
import DigitalFormsCheckIn from './pages/DigitalFormsCheckIn';
//import TawkTo from 'tawkto-react';
//import TawkTo from './TawkTo';


function App() {
  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" 
            element={
              <>
                <Helmet>
                  <title>openDoctor | Innovating Radiology Patient Scheduling</title>
                  <meta name="description" content="openDoctor is a technology company offering best in class solutions to streamline healthcare and radiology patient scheduling." />
                </Helmet>
                <Home />
              </>
            }
          />
          <Route path="/features" 
            element={
              <>
                <Helmet>
                  <title>openDoctor | Radiology Patient Scheduling Solutions</title>
                  <meta name="description" content="openDoctor's software solutions for radiology offices streamline patient scheduling, patient engagement, and office administration." />
                </Helmet>
                <Features />
              </>
            }
          />
          <Route exact path="/integration" 
            element={
              <>
                <Helmet>
                  <title>openDoctor | Radiology Information System Integrations</title>
                  <meta name="description" content="openDoctor's radiology patient scheduling software is configurable and will work with your existing EHR and RIS systems." />
                </Helmet>
                <Integration />
              </>
            }
          />
          <Route exact path="/blog" 
            element={
              <>
                <Helmet>
                  <title>openDoctor | Blog</title>
                  <meta name="description" content="Learn how imaging centers empower patients by adopting modern, patient-centered solutions for their radiology practice." />
                </Helmet>
                <Blog />
              </>
            }
          />
          <Route exact path="/blog/best-practices-for-managing-complex-radiology-scheduling-challenges" 
            element={
              <>
                <Helmet>
                  <title>openDoctor | Blog</title>
                  <meta name="description" content="Learn how imaging centers empower patients by adopting modern, patient-centered solutions for their radiology practice." />
                </Helmet>
                <BlogContent1 />
              </>
            }
          />
          <Route exact path="/blog/selecting-the-best-radiology-patient-scheduling-software-for-your-healthcare-system" 
            element={
              <>
                <Helmet>
                  <title>openDoctor | Blog</title>
                  <meta name="description" content="Learn how imaging centers empower patients by adopting modern, patient-centered solutions for their radiology practice." />
                </Helmet>
                <BlogContent2 />
              </>
            }
          />
          <Route exact path="/blog/how-to-leverage-automated-solutions-to-enhance-patient-engagement-and-satisfaction" 
            element={
              <>
                <Helmet>
                  <title>openDoctor | Blog</title>
                  <meta name="description" content="Learn how imaging centers empower patients by adopting modern, patient-centered solutions for their radiology practice." />
                </Helmet>
                <BlogContent3 />
              </>
            }
          />  
          <Route exact path="/blog/innovation-and-customer-impact-how-online-airfare-booking-can-help-inform-radiology-appointment-scheduling" 
            element={
              <>
                <Helmet>
                  <title>openDoctor | Blog</title>
                  <meta name="description" content="Learn how imaging centers empower patients by adopting modern, patient-centered solutions for their radiology practice." />
                </Helmet>
                <BlogContent4 />
              </>
            }
          />         
          <Route exact path="/customers" 
            element={
              <>
                <Helmet>
                  <title>openDoctor | Radiology Health System Customer Case Studies</title>
                  <meta name="description" content="openDoctor has provided unique radiology scheduling and patient engagement solutions to health systems and practices of various sizes and complexity." />
                </Helmet>
                <Customers />
              </>
            }
          />
          <Route exact path="/roi" 
            element={
              <>
                <Helmet>
                  <title>openDoctor | ROI Calculator</title>
                  <meta name="description" content="openDoctor's solutions streamline radiology patient scheduling, allowing health systems to increase their efficiency and lower administrative costs." />
                </Helmet>
                <Roi />
              </>
            }
          />
          <Route exact path="/company" 
            element={
              <>
                <Helmet>
                  <title>openDoctor | About US</title>
                  <meta name="description" content="openDoctor's team of seasoned technologists, designers and strategists are focused and delivering great radiology patient experiences to the healthcare industry."/>
                </Helmet>
                <Company />
              </>
            }
          />
          <Route path="/careers" 
            element={
              <>
                <Helmet>
                  <title>openDoctor | Careers</title>
                  <meta name="description" content="openDoctor is a technology company offering best in class solutions to streamline healthcare and radiology patient scheduling." />
                </Helmet>
                <Careers />
              </>
            }
          />
          <Route path="/news-updates" 
            element={
              <>
                <Helmet>
                  <title>openDoctor | News and Updates</title>
                  <meta name="description" content="openDoctor is a technology company offering best in class solutions to streamline healthcare and radiology patient scheduling." />
                </Helmet>
                <NewsUpdates />
              </>
            }
          />
          <Route path="/news-updates/opendoctor-and-steinberg-diagnostic-join-forces-for-ordering-and-scheduling-automation" 
            element={
              <>
                <Helmet>
                  <title>openDoctor | News and Updates</title>
                  <meta name="description" content="openDoctor is a technology company offering best in class solutions to streamline healthcare and radiology patient scheduling." />
                </Helmet>
                <NewsUpdatesContent1 />
              </>
            }
          />
          <Route path="/news-updates/philips-and-opendoctor-partner-to-deliver-unprecedented-digital-front-door-experience-in-radiology" 
            element={
              <>
                <Helmet>
                  <title>openDoctor | News and Updates</title>
                  <meta name="description" content="openDoctor is a technology company offering best in class solutions to streamline healthcare and radiology patient scheduling." />
                </Helmet>
                <NewsUpdatesContent2 />
              </>
            }
          />
          <Route path="/news-updates/infinx-healthcare-and-opendoctor-partner-on-an-innovative-approach-to-patient-access" 
            element={
              <>
                <Helmet>
                  <title>openDoctor | News and Updates</title>
                  <meta name="description" content="openDoctor is a technology company offering best in class solutions to streamline healthcare and radiology patient scheduling." />
                </Helmet>
                <NewsUpdatesContent3 />
              </>
            }
          />
          <Route path="/news-updates/tower-radiology-and-opendoctor-partner-for-online-scheduling" 
            element={
              <>
                <Helmet>
                  <title>openDoctor | News and Updates</title>
                  <meta name="description" content="openDoctor is a technology company offering best in class solutions to streamline healthcare and radiology patient scheduling." />
                </Helmet>
                <NewsUpdatesContent4 />
              </>
            }
          />
          <Route path="/news-updates/opendoctor-adds-virtual-registration-client" 
            element={
              <>
                <Helmet>
                  <title>openDoctor | News and Updates</title>
                  <meta name="description" content="openDoctor is a technology company offering best in class solutions to streamline healthcare and radiology patient scheduling." />
                </Helmet>
                <NewsUpdatesContent5 />
              </>
            }
          />
          <Route path="/news-updates/imaginesoftware-and-opendoctor-announce-a-strategic-partnership-streamlining-patient-engagement" 
            element={
              <>
                <Helmet>
                  <title>openDoctor | News and Updates</title>
                  <meta name="description" content="openDoctor is a technology company offering best in class solutions to streamline healthcare and radiology patient scheduling." />
                </Helmet>
                <NewsUpdatesContent6 />
              </>
            }
          />
          <Route path="/news-updates/an-important-statement-regarding-covid-19-opendoctor-is-here-to-help" 
            element={
              <>
                <Helmet>
                  <title>openDoctor | News and Updates</title>
                  <meta name="description" content="openDoctor is a technology company offering best in class solutions to streamline healthcare and radiology patient scheduling." />
                </Helmet>
                <NewsUpdatesContent7 />
              </>
            }
          />
          <Route path="/news-updates/siim-2020-to-feature-opendoctor-production-improvement-by-patient-online-appointment-scheduling-abstract" 
            element={
              <>
                <Helmet>
                  <title>openDoctor | News and Updates</title>
                  <meta name="description" content="openDoctor is a technology company offering best in class solutions to streamline healthcare and radiology patient scheduling." />
                </Helmet>
                <NewsUpdatesContent8 />
              </>
            }
          />
          <Route path="/news-updates/scriptsender-partners-with-opendoctor-to-deliver-streamlined-scheduling-workflow" 
            element={
              <>
                <Helmet>
                  <title>openDoctor | News and Updates</title>
                  <meta name="description" content="openDoctor is a technology company offering best in class solutions to streamline healthcare and radiology patient scheduling." />
                </Helmet>
                <NewsUpdatesContent9 />
              </>
            }
          />
          <Route path="/news-updates/medcurrent-partners-with-opendoctor-to-enable-seamless-radiology-clinical-decision-support-for-ordering-providers" 
            element={
              <>
                <Helmet>
                  <title>openDoctor | News and Updates</title>
                  <meta name="description" content="openDoctor is a technology company offering best in class solutions to streamline healthcare and radiology patient scheduling." />
                </Helmet>
                <NewsUpdatesContent10 />
              </>
            }
          />
          <Route path="/news-updates/text-reminders-prove-to-reduce-no-show-rates-for-imaging-services" 
            element={
              <>
                <Helmet>
                  <title>openDoctor | News and Updates</title>
                  <meta name="description" content="openDoctor is a technology company offering best in class solutions to streamline healthcare and radiology patient scheduling." />
                </Helmet>
                <NewsUpdatesBlog1 />
              </>
            }
          />
          <Route path="/news-updates/opendoctor-hosts-online-symposium-focused-on-consumerism-in-radiology" 
            element={
              <>
                <Helmet>
                  <title>openDoctor | News and Updates</title>
                  <meta name="description" content="openDoctor is a technology company offering best in class solutions to streamline healthcare and radiology patient scheduling." />
                </Helmet>
                <NewsUpdatesBlog2 />
              </>
            }
          />
          <Route path="/news-updates/how-do-high-deductible-health-plans-affect-radiology" 
            element={
              <>
                <Helmet>
                  <title>openDoctor | News and Updates</title>
                  <meta name="description" content="openDoctor is a technology company offering best in class solutions to streamline healthcare and radiology patient scheduling." />
                </Helmet>
                <NewsUpdatesCaseStudy1 />
              </>
            }
          />
          <Route path="/news-updates/university-radiology-makes-strategic-investment-in-opendoctor" 
            element={
              <>
                <Helmet>
                  <title>openDoctor | News and Updates</title>
                  <meta name="description" content="openDoctor is a technology company offering best in class solutions to streamline healthcare and radiology patient scheduling." />
                </Helmet>
                <NewsUpdatesCaseStudy2 />
              </>
            }
          />
          <Route path="/news-updates/patient-portal-adoption-tops-90-patient-registration-lags" 
            element={
              <>
                <Helmet>
                  <title>openDoctor | News and Updates</title>
                  <meta name="description" content="openDoctor is a technology company offering best in class solutions to streamline healthcare and radiology patient scheduling." />
                </Helmet>
                <NewsUpdatesNews1 />
              </>
            }
          />
          <Route path="/news-updates/welch-road-imaging-integrates-ramsoft-powerserver-ris-pacs-opendoctor" 
            element={
              <>
                <Helmet>
                  <title>openDoctor | News and Updates</title>
                  <meta name="description" content="openDoctor is a technology company offering best in class solutions to streamline healthcare and radiology patient scheduling." />
                </Helmet>
                <NewsUpdatesNews2 />
              </>
            }
          />
          <Route path="/news-updates/what-matters-most-to-the-healthcare-consumer" 
            element={
              <>
                <Helmet>
                  <title>openDoctor | News and Updates</title>
                  <meta name="description" content="openDoctor is a technology company offering best in class solutions to streamline healthcare and radiology patient scheduling." />
                </Helmet>
                <NewsUpdatesNews3 />
              </>
            }
          />
          <Route path="/news-updates/opendoctor-lands-covia-health-agreement" 
            element={
              <>
                <Helmet>
                  <title>openDoctor | News and Updates</title>
                  <meta name="description" content="openDoctor is a technology company offering best in class solutions to streamline healthcare and radiology patient scheduling." />
                </Helmet>
                <NewsUpdatesNews4 />
              </>
            }
          />
          <Route path="/news-updates/opendoctor-partners-zwanger-pesiri-real-time-patient-online-scheduling" 
            element={
              <>
                <Helmet>
                  <title>openDoctor | News and Updates</title>
                  <meta name="description" content="openDoctor is a technology company offering best in class solutions to streamline healthcare and radiology patient scheduling." />
                </Helmet>
                <NewsUpdatesUpdates1 />
              </>
            }
          />
          <Route path="/news-updates/patient-engagement-forefront-opendoctor-partnership-steinberg-diagnostic" 
            element={
              <>
                <Helmet>
                  <title>openDoctor | News and Updates</title>
                  <meta name="description" content="openDoctor is a technology company offering best in class solutions to streamline healthcare and radiology patient scheduling." />
                </Helmet>
                <NewsUpdatesUpdates2 />
              </>
            }
          />
          <Route path="/news-updates/why-patients-wont-request-an-appointment-much-longer" 
            element={
              <>
                <Helmet>
                  <title>openDoctor | News and Updates</title>
                  <meta name="description" content="openDoctor is a technology company offering best in class solutions to streamline healthcare and radiology patient scheduling." />
                </Helmet>
                <NewsUpdatesUpdates3 />
              </>
            }
          />
          <Route path="/news-updates/what-does-consumerism-in-healthcare-look-like" 
            element={
              <>
                <Helmet>
                  <title>openDoctor | News and Updates</title>
                  <meta name="description" content="openDoctor is a technology company offering best in class solutions to streamline healthcare and radiology patient scheduling." />
                </Helmet>
                <NewsUpdatesUpdates4 />
              </>
            }
          />
          <Route exact path="/contact-us" 
            element={
              <>
                <Helmet>
                  <title>openDoctor | Contact US</title>
                  <meta name="description" content="Contact openDoctor to learn more about how your health system can benefit from our radiology patient scheduling and patient  experience solutions." />
                </Helmet>
                <ContactUs />
              </>
            }
          />
          <Route exact path="/faq" 
            element={
              <>
                <Helmet>
                  <title>openDoctor | Faq</title>
                  <meta name="description" content="Find answers to common questions about openDoctor's healthcare and radiology scheduling software solutions. Explore our FAQ to learn more about our services and features." />
                </Helmet>
                <Faq />
              </>
            }
          />
          <Route exact path="/terms-conditions" 
            element={
              <>
                <Helmet>
                  <title>openDoctor | Terms of Service</title>
                  <meta name="description" content="Review the openDoctor Terms of Service to understand the rules, conditions, and policies that govern the use of our healthcare and radiology online services." />
                </Helmet>
                <TermsConditions />
              </>
            }
          />
          <Route exact path="/privacy-policy" 
            element={
              <>
                <Helmet>
                  <title>openDoctor | Privacy Policy</title>
                  <meta name="description" content="Read our Privacy Policy to learn how we collect, use, and protect your personal information. Understand our commitment to data security for online radiology solutions." />
                </Helmet>
                <PrivacyPolicy />
              </>
            }
          />
          <Route path="/inquiry" element={<Inquiry />} />
          <Route path="/careers/project-manager" element={<ProjectManager />} />
          <Route path="/careers/quality-assurance-tester" element={<QualityAssuranceTester />} />
          <Route path="/careers/senior-php-developer" element={<SeniorPhpDeveloper />} />
          <Route path="/careers/business-analyst-intern" element={<BusinessAnalystIntern />} />
          <Route path="/careers/interface-developer-intern" element={<InterfaceDeveloperIntern />} />
          <Route path="/careers/full-stack-software-developer" 
            element={
              <>
                <Helmet>
                  <title>openDoctor | Careers</title>
                  <meta name="description" content="openDoctor is a technology company offering best in class solutions to streamline healthcare and radiology patient scheduling." />
                </Helmet>
                <FullStackSoftwareDeveloper />
              </>
            }
          />
          <Route path="/careers/automation-test-engineer" 
            element={
              <>
                <Helmet>
                  <title>openDoctor | Careers</title>
                  <meta name="description" content="openDoctor is a technology company offering best in class solutions to streamline healthcare and radiology patient scheduling." />
                </Helmet>
                <AutomationTestEngineer />
              </>
            }
          />
          <Route path="/enterprise-solution" element={<EnterpriseSolution />} />
          <Route path="/reminders-notifications" element={<RemindersNotifications />} />
          <Route path="/digital-forms-checkin" element={<DigitalFormsCheckIn />} />
        </Routes>

        <ScrollToTop />

        <Footer />
        {/*<TawkTo />*/}

      </BrowserRouter>
    </>
  );
}

export default App;
