import React from 'react';
import { NavLink } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import { Linkedin } from 'react-bootstrap-icons';
//import EmailSubscribe from '../components/EmailSubscribe';
import PageBottom from '../components/PageBottom'

const Footer = () => {
    return (
        <>
         <PageBottom />
            <div className="bg-primary footer">
                <Container fluid className="py-4 px-lg-5 px-sm-4">
                    <Row>
                        <Col lg={10} sm={7}>
                            <Row>
                                <Col lg={2} sm={6}>
                                    <NavLink to="/">Home</NavLink>
                                    <a href="https://portal.opendr.com/signin" rel="noreferrer" target="_blank">Customer Login</a>
                                    <a href="https://portal.opendr.com/signin" rel="noreferrer" target="_blank">Referring Provider login</a>
                                </Col>
                                <Col lg={3} sm={6}>
                                    <NavLink to="/features">Radiology Patient Scheduling</NavLink>
                                    <NavLink to="/features">Radiology Patient Engagement</NavLink>
                                    <NavLink to="/features">Radiology Referral Management</NavLink>                                    
                                </Col>
                                <Col lg={2} sm={6}>
                                    <NavLink to="/integration">Integration</NavLink>
                                    <NavLink to="/blog">Blog</NavLink>
                                    <NavLink to="/roi">ROI Calculator</NavLink>                                    
                                    {/*<NavLink to="/careers">Careers</NavLink>*/}
                                </Col>
                                <Col lg={2} sm={6}>
                                    <NavLink to="/customers">Customers</NavLink>
                                    <NavLink to="/company">About us</NavLink>
                                    <NavLink to="/contact-us">Contact Us</NavLink>
                                </Col>
                                <Col lg={2} sm={6}>
                                    {/*<NavLink to="/news-updates">News & Updates</NavLink>*/}
                                    <NavLink to="/faq">FAQ</NavLink>
                                    <NavLink to="/terms-conditions">Terms & Conditions</NavLink>
                                    <NavLink to="/privacy-policy">Privacy Policy</NavLink>                                    
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={2} sm={5} className="text-lg-end text-sm-start my-2">

                            {/*<EmailSubscribe /> */}

                            <div>
                                {/*
                                <a className='d-inline opacity-100' href="https://twitter.com/intent/tweet?text=Integration - OpenDR&url=https%3A%2F%2Fopendr.com%2Fintegration%2F" rel="noreferrer" target="_blank"><Twitter color='#ffffff' size="25" href='' /></a>
                                <a className='d-inline opacity-100' href="https://www.facebook.com/login.php?skip_api_login=1&api_key=966242223397117&signed_next=1&next=https%3A%2F%2Fwww.facebook.com%2Fsharer.php%3Ft%3DIntegration%2B-%2BOpenDR%26u%3Dhttps%253A%252F%252Fopendr.com%252Fintegration%252F&cancel_url=https%3A%2F%2Fwww.facebook.com%2Fdialog%2Fclose_window%2F%3Fapp_id%3D966242223397117%26connect%3D0%23_%3D_&display=popup&locale=en_GB" rel="noreferrer" target="_blank"><Facebook color='#ffffff' size="25" className='ms-4' /></a>
                                */}
                                <a className='d-inline opacity-100 border linkedin ' href="https://www.linkedin.com/company/2222959/admin/feed/posts/" rel="noreferrer" target="_blank"><span>Linked</span> <Linkedin color='#ffffff' size="25" /> </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className="bg-primary footer_bottom text-white text-center p-3 text-uppercase">
                    <small>
                        Copyright © {new Date().getFullYear()} openDoctor. All rights reserved.
                    </small>
                </div>
            </div>
        </>
    )
}
export default Footer